import React from 'react';
import SubCategory from './sub-category';


const DanfossSubCategories = () => {
  const categories = ['Vacon', 'Vlt', 'IC'];

  return <SubCategory category="danfoss" subCategories={categories} baseUrl="/danfoss" />;
};

export default DanfossSubCategories;
