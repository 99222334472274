import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'

const Services = () => {
  return (
    <Container>
        <h2 className="text-center mt-5 mb-5">HİZMETLERİMİZ</h2>
    <div className="text-center">
      <Row className="cards" style={{ fontWeight: "bold" }}>
        <Col sm={12} md={4} className="mb-4">
          <Card style={{ width: "100%" }}>
            <Card.Img
              variant="top"
              src="images/our-services/muhendislik.jpg"
            />
            <Card.Body>
              <Card.Text>Mühendislik Hizmetleri</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={4} className="mb-4">
          <Card style={{ width: "100%" }}>
            <Card.Img
              variant="top"
              src="images/our-services/scala-hizmetleri.webp"
            />
            <Card.Body>
              <Card.Text>Fabrika Otomasyonu Scada Sistemleri</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={4} className="mb-4">
          <Card style={{ width: "100%" }}>
            <Card.Img
              variant="top"
              src="images/our-services/scada-yazilimm.png"
            />
            <Card.Body>
              <Card.Text>Scada Yazılım ve Otomasyon Sistemleri</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={4} className="mb-4">
          <Card style={{ width: "100%" }}>
            <Card.Img
              variant="top"
              src="images/our-services/danismanlik-hizmeti.webp"
            />
            <Card.Body>
              <Card.Text>Danışmanlık Hizmetleri</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={4} className="mb-4">
          <Card style={{ width: "100%" }}>
            <Card.Img
              variant="top"
              src="images/our-services/elektrik-elektronik-sistemi.jpg"
            />
            <Card.Body>
              <Card.Text>Elektrik ve Elektronik Sistemleri</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={4} className="mb-4">
          <Card style={{ width: "100%" }}>
            <Card.Img
              variant="top"
              src="images/our-services/yazilim-hizmetleri.jpg"
            />
            <Card.Body>
              <Card.Text>Yazılım Sistemleri</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
    </Container>
  )
}

export default Services