import React from 'react';
import { Container, Row, Col, Card, Button, Nav } from 'react-bootstrap';
import PageHeader from '../common/page-header';
import { Link } from 'react-router-dom';

const SiemensVideos = () => {
 
     const videoId1 = 'pRRHgc28zq4'; 
     const videoId2 = '31KQI8Vjvck'; 
     const videoId3 = 'NX-IuIrgqNg'; 
     const videoId4 = 'xtruhSQcxrs'; 
     const videoId5 = 'K25vXdNCKs0'; 
     const videoId6 = 'hVVbpH4Xma4'; 
     const videoId7 = 'KymbvyAXY0Q'; 
     const videoId8 = 'qepkane1gAE'; 
     const videoId9 = 'qtV-MrXybvk'; 
    
    return (
      <Container className="mt-5">
        <PageHeader title="SİEMENS EĞİTİM VİDEOLARI" />
        <Nav.Link as={Link} to="/video" className='text-center mt-5'> <Button   >Videolar Geri Dön</Button></Nav.Link>  
        <Row className="justify-content-md-center text-center">
          <Col md={12} className="d-flex gap-2 flex-wrap justify-content-center" style={{fontWeight:"700", fontSize:"17px"}}>
          <Card >
              <Card.Body>
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item custom-iframe"
                    src={`https://www.youtube.com/embed/${videoId1}`}
                    allowFullScreen
                    title="YouTube Video"
                  ></iframe>
                </div>
                <Card.Text className="mt-3" >
                SIMATIC S7-1200 - the Basic Controller
                </Card.Text>
                <Button variant="primary" href={`https://www.youtube.com/watch?v=${videoId1}`} target="_blank">
                  YouTube'da izle
                </Button>
              </Card.Body>
            </Card>
          <Card>
              <Card.Body>
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item custom-iframe"
                    src={`https://www.youtube.com/embed/${videoId2}`}
                    allowFullScreen
                    title="YouTube Video"
                  ></iframe>
                </div>
                <Card.Text className="mt-3">
                SINAMICS V90 with PROFINET
                </Card.Text>
                <Button variant="primary" href={`https://www.youtube.com/watch?v=${videoId2}`} target="_blank">
                  YouTube'da izle
                </Button>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item custom-iframe"
                    src={`https://www.youtube.com/embed/${videoId3}`}
                    allowFullScreen
                    title="YouTube Video"
                  ></iframe>
                </div>
                <Card.Text className="mt-3">
                 SIMATIC ET 200SP
                </Card.Text>
                <Button variant="primary" href={`https://www.youtube.com/watch?v=${videoId3}`} target="_blank">
                  YouTube'da izle
                </Button>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item custom-iframe"
                    src={`https://www.youtube.com/embed/${videoId4}`}
                    allowFullScreen
                    title="YouTube Video"
                  ></iframe>
                </div>
                <Card.Text className="mt-3">
                SIMATIC IOT2040
                </Card.Text>
                <Button variant="primary" href={`https://www.youtube.com/watch?v=${videoId4}`} target="_blank">
                  YouTube'da izle
                </Button>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item custom-iframe"
                    src={`https://www.youtube.com/embed/${videoId5}`}
                    allowFullScreen
                    title="YouTube Video"
                  ></iframe>
                </div>
                <Card.Text className="mt-3">
                SIMATIC S7-1500 and ET 200MP
                </Card.Text>
                <Button variant="primary" href={`https://www.youtube.com/watch?v=${videoId5}`} target="_blank">
                  YouTube'da izle
                </Button>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item custom-iframe"
                    src={`https://www.youtube.com/embed/${videoId6}`}
                    allowFullScreen
                    title="YouTube Video"
                  ></iframe>
                </div>
                <Card.Text className="mt-3">
                SIMATIC S7-1500 Failsafe Controller 
                </Card.Text>
                <Button variant="primary" href={`https://www.youtube.com/watch?v=${videoId6}`} target="_blank">
                  YouTube'da izle
                </Button>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item custom-iframe"
                    src={`https://www.youtube.com/embed/${videoId7}`}
                    allowFullScreen
                    title="YouTube Video"
                  ></iframe>
                </div>
                <Card.Text className="mt-3">
                S7-1200F ve Acil Stop Kullanımı
                </Card.Text>
                <Button variant="primary" href={`https://www.youtube.com/watch?v=${videoId7}`} target="_blank">
                  YouTube'da izle
                </Button>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item custom-iframe"
                    src={`https://www.youtube.com/embed/${videoId8}`}
                    allowFullScreen
                    title="YouTube Video"
                  ></iframe>
                </div>
                <Card.Text className="mt-3">
                SIMATIC HMI Panels
                </Card.Text>
                <Button variant="primary" href={`https://www.youtube.com/watch?v=${videoId8}`} target="_blank">
                  YouTube'da izle
                </Button>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item custom-iframe"
                    src={`https://www.youtube.com/embed/${videoId9}`}
                    allowFullScreen
                    title="YouTube Video"
                  ></iframe>
                </div>
                <Card.Text className="mt-3">
                SIMATIC HMI Panel'de Alarm Gösterimi
                </Card.Text>
                <Button variant="primary" href={`https://www.youtube.com/watch?v=${videoId9}`} target="_blank">
                  YouTube'da izle
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
}

export default SiemensVideos