import React, { useState } from 'react';
import { Card, Col, Container, Row, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import photos from "../../helpers/data/slider.json";
import "./our-products.scss";
import SiemensSubCategories from './siemens-sub-categories';
import DanfossSubCategories from './danfoss-sub-categories';

const OurProducts = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setShowModal(false);
  };

  return (
    
    <Container className='galeri' style={{ overflowX: 'hidden' }}>
      
      <Row>
        <Col md={3} className='kategori mt-5'>
          <h2 className='fw-bold'>Kategoriler</h2>
          <ul style={{ fontWeight: "bold", fontSize: "18px"}}>
            <li>
              <SiemensSubCategories />
            </li>
            <li>
              <DanfossSubCategories />
            </li>
          </ul>
        </Col>
        <Col md={9} className='mt-5'>
          <Row className="photo w-100" style={{ cursor: "pointer" }}>
            {photos.slice(0, 12).map((item) => (
              <Col key={item.id} md={4} className='mb-4' onClick={() => handleImageClick(item.image)}>
                <Card>
                  <Card.Body>
                    <Card.Img src={`/images/slider/${item.image}`} className="img-fluid" />
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered >
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body>
          {selectedImage && <img src={`/images/slider/${selectedImage}`} className="img-fluid" alt="Large" style={{ maxWidth: "100%", maxHeight: "80vh", margin: "0 auto" }} />}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleCloseModal}>Kapat</button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default OurProducts;
