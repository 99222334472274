import React from 'react';
import "./deneme-sayfasi.scss";
import { Col, Container, Row } from 'react-bootstrap';

const products = [
  { id: 1, name: 'V-90', description: '6SL3210-5FB10-8UF0 - SINAMICS V90', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRADOVKh-B5xs0n4oF8GCDTVQJAYxUpLbjLEg&s' },
  { id: 2, name: 'MTP-700', description: '6AV2128-3MB06-0AX0 (TP1200)', image: 'https://mall.industry.siemens.com/mall/collaterals/files/165/jpg/P_ST80_XX_03429i.jpg' },
  { id: 3, name: 'S71200', description: 'Siemens SIMATIC S7-1200 Series PLC CPU', image: 'https://n11scdn.akamaized.net/a1/375_535/08/74/15/79/IMG-7278695452151101286.jpg' },
  { id: 3, name: 'FC-302', description: 'Danfoss Vlt Drive FC-302', image: 'https://5.imimg.com/data5/SELLER/Default/2023/9/340367042/VZ/LB/RK/8774235/fc-102-danfoss-enhanced-vlt-hvac-drive-500x500.jpg' },
  { id: 3, name: 'FC-280', description: 'Danfoss Vlt Midi Drive FC-280', image: 'https://www.beosis.com.tr/upload/FC-280/FC-280-Midi-Drive-.jpg' },
  { id: 3, name: 'FC-102', description: 'Danfoss Vlt Drive FC-102', image: 'https://esnenerji.com.tr/wp-content/uploads/2023/08/VLT_Basic_H1_IP20.jpg' },
];

function FeaturedProducts() {
  return (
    <div className="featured-products">
     <Container>
    <Row>
      <Col>
      <h2 className='text-center'>Öne Çıkan Ürünler</h2>
      <div className="products">
        {products.map(product => (
          <div key={product.id} className="product-card">
            <img src={product.image} alt={product.name} style={{ height:"50%"}} />
            <h3>{product.name}</h3>
            <p>{product.description}</p>
          </div>
        ))}
      </div>
      </Col>
    </Row>
     </Container>
    </div>
  );
}

export default FeaturedProducts;
