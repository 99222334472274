// import React, { useState, useRef, useEffect } from 'react';
// import './video-player.scss';

// const VideoPlayer = () => {
//   const [isOpen, setIsOpen] = useState(true);
//   const modalRef = useRef(null);

//   const handleClose = () => {
//     setIsOpen(false);
//   };

//   const handleClickOutside = (event) => {
//     if (modalRef.current && !modalRef.current.contains(event.target)) {
//       handleClose();
//     }
//   };

//   useEffect(() => {
//     document.addEventListener('mousedown', handleClickOutside);
//     return () => document.removeEventListener('mousedown', handleClickOutside);
//   }, []);

//   return (
//     <>
     
//       {isOpen && (
//         <div className="video-modal mt-5">
//           <div className="video-modal-content" ref={modalRef}>
           
//             <video  poster="/images/kampanya-3.jpg">
//              <a href="#iletisim"></a>
//             </video>
           
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default VideoPlayer;



//  import React, { useState, useRef, useEffect } from 'react';
//  import './video-player.scss';

//  const ImageModal = () => {
//    const [isOpen, setIsOpen] = useState(true);
//    const modalRef = useRef(null);

//    const handleClose = () => {
//      setIsOpen(false);
//    };

//    const handleClickOutside = (event) => {
//      if (modalRef.current && !modalRef.current.contains(event.target)) {
//        handleClose();
//     }
//   };

//    useEffect(() => {
//      document.addEventListener('mousedown', handleClickOutside);
//      return () => document.removeEventListener('mousedown', handleClickOutside);
//    }, []);

//    return (
//      <>
//        {isOpen && (
//          <div className="image-modal mt-5">
//            <div className="image-modal-content" ref={modalRef}>
//              <img className='modal-image' src="/images/kampanya-2.jpg" alt="Logo" />
//            </div>
//          </div>
//        )}
//      </>
//    );
//  };

// export default ImageModal;








import React, { useState, useRef, useEffect } from "react";
import "./video-player.scss";

const ImageModal = () => {
  const [isOpen, setIsOpen] = useState(true);
  const modalRef = useRef(null);
  const videoRef = useRef(null);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = 1.4; // Videoyu 2. saniyeden başlatır
    }
  }, []);

  return (
    <>
      {isOpen && (
        <div className="image-modal mt-5">
          <div className="image-modal-content" ref={modalRef}>
            <video
              ref={videoRef}
              src="/images/player/video-danfoss.mp4"
              autoPlay
              loop
              controls
              className="img-fluid"
            >
              <source src="/player/video-danfoss.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageModal;


