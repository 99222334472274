import React from 'react';
import PageHeader from '../../common/page-header';
import "./user-guide.scss"

const SiemensUserGuide = () => {
  const guides = [
    { 
      name: 'SIMATIC S7-1200 otomasyon sistemi', 
      link: 'https://cache.industry.siemens.com/dl/files/829/109814829/att_1144373/v1/s71200_system_manual_tr-TR_tr-TR.pdf',
      description: 'SIMATIC S7-1200 otomasyon sistemi'
    },
    { 
      name: 'SIMATIC S7-1500/ET 200MP, S7-1500R/H', 
      link: 'https://cache.industry.siemens.com/dl/files/815/68052815/att_1161023/v3/s71500_et200mp_s71500rh_product_information_tr-TR_tr-TR.pdf',
      description: 'SIMATIC S7-1500/ET 200MP, S7-1500R/H'
    },
    { 
      name: 'SIMATIC ET 200SP Desantral Çevrebirim Sistemi', 
      link: 'https://cache.industry.siemens.com/dl/files/864/73021864/att_1161057/v1/et200sp_product_information_tr-TR_tr-TR.pdf',
      description: 'SIMATIC ET 200SP Desantral Çevrebirim Sistemi'
    },
    { 
      name: 'SIMATIC Safety - Projelendirme ve programlama', 
      link: 'https://cache.industry.siemens.com/dl/files/126/54110126/att_1167234/v1/safety_conf_prog_programming_manual_tr-TR_tr-TR.pdf',
      description: 'SIMATIC Safety - Projelendirme ve programlama'
    },
    { 
      name: 'SIMATIC Desantral Çevrebirim Cihazı ET 200M', 
      link: 'https://cache.industry.siemens.com/dl/files/798/1142798/att_1107916/v1/et200M_operating_instructions_tr-TR_tr-TR.pdf',
      description: 'SIMATIC Desantral Çevrebirim Cihazı ET 200M'
    },
    { 
      name: 'SIMATIC HMI Communication For Windows-based Systems', 
      link: 'https://cache.industry.siemens.com/dl/files/528/1405528/att_47323/v1/comb1V6_e.pdf',
      description: 'SIMATIC Desantral Çevrebirim Cihazı ET 200M'
    },
    { 
      name: 'KTP Mobil Panelinin SIMATIC Manager İle Birlikte Kullanma', 
      link: 'https://cache.industry.siemens.com/dl/files/114/109741114/att_975212/v1/109741114_KTP_Mobile_STEP7_V5_tr.pdf',
      description: 'KTP Mobil Panelinin SIMATIC Manager İle Birlikte Kullanma'
    },
  ];

  return (
    <div className="guide-container">
      <PageHeader title="Siemens Kullanım Kılavuzları" />
      <ul className="guide-list text-center mt-3">
        {guides.map((guide, index) => (
          <li key={index} className="guide-item fw-bold">
            <a href={guide.link} target="_blank" rel="noopener noreferrer" className="guide-link">
              {guide.name}
            </a>
            <p className="guide-description">{guide.description}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SiemensUserGuide;
