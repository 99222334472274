import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./footer-menu.scss";

const FooterMenu = (props) => {
  return (
    <div className="footer-menu">
      <Nav {...props}>
        <Nav.Link as={Link} to="/" style={{ color: "#383086" }}>
          Anasayfa
        </Nav.Link>
        <Nav.Link as={Link} to="/urunlerimiz" style={{ color: "#383086" }}>
          Ürünlerimiz
        </Nav.Link>
        <Nav.Link as={Link} to="/katalog" style={{ color: "#383086" }}>
          Katalog
        </Nav.Link>
        <Nav.Link as={Link} to="/hakkimizda" style={{ color: "#383086" }}>
          Hakkımızda
        </Nav.Link>
      </Nav>
    </div>
  );
};

export default FooterMenu;
