import React from "react";
import { Card, Container } from "react-bootstrap";
import "./danfoss.scss"

const Danfoss = ({image}) => {
  return (
   <Container>
      <Card className="danfoss">
        <Card.Body>
          <div className="image">
            <Card.Img src={`images/danfoss-urunler/${image}`} className="img-fluid" />
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Danfoss;
