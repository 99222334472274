import React from "react";
import { Container, Grid } from "@mui/material";
import ProductCard from "./product-card.jsx";
import BackgroundLogo from "../common/background-logo.jsx";
import PageHeader from "../common/page-header.jsx";

const products = [
  {
    name: "SIEMENS-ENDÜSTRİYEL",
    description: "Fiyat Listesi- Ocak 2024",
    image: "https://r.resimlink.com/TxhOkjPyU.jpg", // Placeholder resim, gerçek resim URL'si ile değiştirin
    catalogLink:
      "https://assets.new.siemens.com/siemens/assets/api/uuid:aa4d7d07-5360-4983-beda-c43b61617b56/SIEMENS-OTOMASYON-MASTER-20240227.pdf.pdf",
  },
  {
    name: "SIEMENS-ŞALT",
    description: "Fiyat Listesi - 22 Nisan 2024",
    image: "https://r.resimlink.com/LzPcIG.png",
    catalogLink:
      "https://assets.new.siemens.com/siemens/assets/api/uuid:244de036-4c28-4e6d-b260-e2f16c4cf29f/Salt-ve-Enstalasyon-Teknigi-Fiyat-Listesi-22-Nisan-2024.pdf",
  },
  {
    name: "DANFOSS-VLT",
    description: "Danfoss VLT Drives VLT® Product Catalogue",
    image: "https://r.resimlink.com/LW8PO.png",
    catalogLink: "https://www.drivesatis.com/downloads/danfossgenelkat.pdf",
  },
  {
    name: "VACON® NXP and VACON® NXC AC drives",
    description: "Precision and clean power in a compact package",
    image: "https://r.resimlink.com/xRuS9.png",
    catalogLink:
      "https://k2drives.co.uk/materials/danfoss_vacon_nxp_and_nxc_guide_k2_drives_2017.pdf",
  },
  {
    name: "iC2-Micro & VLT® Micro Drive FC 51",
    description:
      "Comparison of features and specifications for iC2-Micro and VLT® Micro Drive FC 51",
    image: "https://r.resimlink.com/kvHBAe_GNcKU.png",
    catalogLink:
      "https://assets.danfoss.com/documents/373446/AD423936930805en-000401.pdf",
  },
  {
    name: "iC7-Automation Frequency Converters",
    description: "iC7-Automation Frequency Converters 1.3-1260 A",
    image: "https://r.resimlink.com/3FvOGCm9.png",
    catalogLink:
      "https://files.danfoss.com/download/Drives/M0027101_AJ319739940640en-000301_iC7_Automation_Frequency_Converters_1.3-1260A_DG.pdf",
  },
];

const ProductsList = () => {
  return (
    <>
      <PageHeader title="KATALOG" />
      <BackgroundLogo />
      <Container className="text-center">
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          {products.map((product, index) => (
            <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
              <ProductCard product={product} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default ProductsList;
