import React from "react";
import { Button, Dropdown, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import "./main-menu.scss";

const MainMenu = ({ onLinkClick }) => {
  const location = useLocation();

  const getLinkClass = (path) =>
    classNames({
      "main-menu-link": false,
      "active-link": location.pathname === path,
    });

  return (
    <Nav>
      <Nav.Link
        as={Link}
        to="/"
        className={getLinkClass("/")}
        onClick={onLinkClick}
      >
        <Button className="menubar-btn anasayfa" variant="primary">
          Anasayfa
        </Button>
      </Nav.Link>
      <Nav.Link
        as={Link}
        to="/urunlerimiz"
        className={getLinkClass("/urunlerimiz")}
        onClick={onLinkClick}
      >
        <Button className="menubar-btn" variant="primary">
          Ürünlerimiz
        </Button>
      </Nav.Link>
      <Nav.Link
        as={Link}
        to="/katalog"
        className={getLinkClass("/katalog")}
        onClick={onLinkClick}
      >
        <Button className="menubar-btn" variant="primary">
          Katalog
        </Button>
      </Nav.Link>
      <Nav.Link
        as={Link}
        to="/hakkimizda"
        className={getLinkClass("/hakkimizda")}
        onClick={onLinkClick}
      >
        <Button className="menubar-btn" variant="primary">
          Hakkımızda
        </Button>
      </Nav.Link>
      <Nav.Link>
        <Dropdown>
          <Dropdown.Toggle
            className="menubar-btn"
            variant="primary"
            id="dropdown-basic"
          >
            Projeler
          </Dropdown.Toggle>
          <Dropdown.Menu className="text-center">
            <Dropdown.Item as={Link} to="/gaski" onClick={onLinkClick}>
              GASKİ
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/tpao" onClick={onLinkClick}>
              TPAO
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/suski" onClick={onLinkClick}>
              ŞUŞKİ
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Nav.Link>
      <Nav.Link>
        <Dropdown>
          <Dropdown.Toggle
            className="menubar-btn"
            variant="primary"
            id="dropdown-basic"
          >
            Dökümanlar
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown as={Dropdown.Submenu}>
              <Dropdown.Toggle
                variant="light"
                to="/video"
                id="dropdown-basic"
                style={{ width: "100%" }}
              >
                Videolar
              </Dropdown.Toggle>
              <Dropdown.Menu className="text-center">
              
                  <Nav.Link
                    as={Link}
                    to="/danfoss-video"
                    className={getLinkClass("/danfoss-video")}
                    onClick={onLinkClick}
                  >
                    <Button
                      style={{ width: "80%" }}
              
                      variant="secondary"
                    >
                      Danfoss
                    </Button>
                  </Nav.Link>
                
              
                  <Nav.Link
                    as={Link}
                    to="/siemens-video"
                    className={getLinkClass("/siemens-video")}
                    onClick={onLinkClick}
                  >
                    <Button
                      style={{ width: "80%" }}
              
                      variant="warning"
                    >
                      Siemens
                    </Button>
                  </Nav.Link>
             
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown as={Dropdown.Submenu}>
              <Dropdown.Toggle
                variant="light"
                id="dropdown-basic"
                style={{ width: "100%" }}
              >
                Kullanım Kılavuzu
              </Dropdown.Toggle>
              <Dropdown.Menu className="text-center">
                <Nav.Link as={Link} to="/danfoss-kilavuz" onClick={onLinkClick}>
                  <Button style={{ width: "80%" }} variant="secondary">
                    Danfoss
                  </Button>
                </Nav.Link>
                <Nav.Link as={Link} to="/siemens-kilavuz" onClick={onLinkClick}>
                  <Button style={{ width: "80%" }} variant="warning">
                    Siemens
                  </Button>
                </Nav.Link>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown as={Dropdown.Submenu}>
              <Dropdown.Toggle
                variant="light"
                id="dropdown-basic"
                style={{ width: "100%" }}
              >
                Uygulamalar
              </Dropdown.Toggle>
              <Dropdown.Menu className="text-center">
                <Nav.Link
                  as={Link}
                  to="/danfoss-uygulamalar"
                  className={getLinkClass("/danfoss-uygulamalar")}
                  onClick={onLinkClick}
                >
                  <Button
                    style={{ width: "80%" }}
           
                    variant="secondary"
                  >
                    Danfoss
                  </Button>
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/siemens-uygulamalar"
                  className={getLinkClass("/siemens-uygulamalar")}
                  onClick={onLinkClick}
                >
                  <Button
                    style={{ width: "80%" }}
          
                    variant="warning"
                  >
                    Siemens
                  </Button>
                </Nav.Link>
              </Dropdown.Menu>
            </Dropdown>
          </Dropdown.Menu>
        </Dropdown>
      </Nav.Link>
      <Nav.Link
        as={Link}
        to="/haberler"
        className={getLinkClass("/haberler")}
        onClick={onLinkClick}
      >
        <Button className="menubar-btn" variant="primary">
          Haberler
        </Button>
      </Nav.Link>
      <Nav.Link
        as={Link}
        to="/iletisim"
        className={getLinkClass("/iletisim")}
        onClick={onLinkClick}
      >
        <Button className="menubar-btn" variant="primary">
          İletişim
        </Button>
      </Nav.Link>
    </Nav>
  );
};

export default MainMenu;
