import React from 'react';
import SubCategory from './sub-category';
import ProductCard from './product-card';
import PageHeader from '../common/page-header';
import { Col, Container, Row } from 'react-bootstrap';
import { FaArrowAltCircleRight } from "react-icons/fa";


const PlcSubCategory = () => {
  const products = Array.from({ length: 10 }, (_, index) => ({
    id: index + 1,
    name: `PLC Ürün ${index + 1}`,
    image: `/images/plc/product${index + 1}.jpg`
  }));

  return (
    <>
    <PageHeader title="SİEMENS PLC" />
    
      <SubCategory category="" subCategories={[]} baseUrl="/siemens/plc" />
      <div className='text-center'>
      <a href="/urunlerimiz" style={{fontWeight:"bold", textDecoration:"none", fontSize:"20px"}}><FaArrowAltCircleRight /> Ürünlerimiz Ana sayfa</a>
      </div>
      <Container>
      
        <Row >
            <Col>
            <div className="product-container">
        {products.map(product => (
          <ProductCard key={product.id} name={product.name} image={product.image} />
        ))}
      </div>
            </Col>
        </Row>
      </Container>
    </>
  );
};

export default PlcSubCategory;
