import React from 'react';
import SubCategory from './sub-category';


const SiemensSubCategories = () => {
  const categories = ['Plc', 'Servo', 'Hmi'];

  return <SubCategory category="siemens" subCategories={categories} baseUrl="/siemens" />;
};

export default SiemensSubCategories;
