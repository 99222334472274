import React from "react";
import { Button, Col, Container, Nav, Row } from "react-bootstrap";
import PageHeader from "../common/page-header";
import Spacer from "../common/spacer";
import { Link } from "react-router-dom";
import "./videos.scss";
import BackgroundLogo from "../common/background-logo";

const Videos = () => {
  return (
    <div>
      <PageHeader title="EĞİTİM VİDEOLARI" />
      <BackgroundLogo/>
      <Spacer />
      <Container className="text-center mt-5">
        <Row>
          <Col className="buton">
            <Button
              style={{
                height: "150%",
                width: "30%",
                fontSize: "25px",
                fontWeight: "bold",
              }}
              variant="secondary"
            >
              {" "}
              <Nav.Link as={Link} to="/danfoss-video">
                {" "}
                Danfoss Eğitim Videoları{" "}
              </Nav.Link>
            </Button>

            <Button
              style={{
                height: "150%",
                width: "30%",
                fontSize: "25px",
                fontWeight: "bold",
                color: "white",
              }}
              variant="warning"
            >
              <Nav.Link as={Link} to="/siemens-video">
                Siemens Eğitim Videoları
              </Nav.Link>
            </Button>
          </Col>
        </Row>
      </Container>
      <Spacer />
    </div>
  );
};

export default Videos;
