import React from "react";
import PageHeader from "../common/page-header";
import SubCategory from "./sub-category";
import ProductCard from "./product-card";
import { Col, Container, Row } from "react-bootstrap";
import { FaArrowAltCircleRight } from "react-icons/fa";

const HmiSubCategory = () => {
  const products = Array.from({ length: 10 }, (_, index) => ({
    id: index + 1,
    name: `Hmi Ürün ${index + 1}`,
    image: `/images/sht/product${index + 1}.jpg`,
  }));

  return (
    <>
      <PageHeader title="SİEMENS HMI" />
      <Container>
        <Row>
          <Col>
            <SubCategory
              category=""
              subCategories={[]}
              baseUrl="/siemens/hmi"
            />
            <div className="text-center">
              <a
                href="/urunlerimiz"
                style={{
                  fontWeight: "bold",
                  textDecoration: "none",
                  fontSize: "20px",
                }}
              >
                <FaArrowAltCircleRight /> Ürünlerimiz Ana sayfa
              </a>
            </div>
            <div className="product-container">
              {products.map((product) => (
                <ProductCard
                  key={product.id}
                  name={product.name}
                  image={product.image}
                />
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HmiSubCategory;
