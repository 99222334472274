import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowCircleRight } from 'react-icons/fa';

const SubCategory = ({ category, subCategories, baseUrl }) => {
  const [selectedSubCategory, setSelectedSubCategory] = React.useState(null);

  return (
    <>
      <Link
        to="#"
        onClick={() => setSelectedSubCategory(selectedSubCategory === category ? null : category)}
        style={{ textDecoration: 'none' }}
      >
        <FaArrowCircleRight /> {category.toUpperCase()}
      </Link>
      {selectedSubCategory === category && (
        <ul style={{ textDecoration: "none", text:"none" ,paddingLeft: "20px" }}>
          {subCategories.map((sub, index) => (
            <li key={index}>
              <Link to={`${baseUrl}/${sub.toLowerCase().replace(/ /g, '-')}`}>
                {sub}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default SubCategory;
