/* import React, { useState } from "react";
import { Container, Image, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { config } from "../../helpers/config";
import MainMenu from "./main-menu";
import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import "./menubar.scss";


const Menubar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuClose = () => setShowMenu(false);

  return (
    <Navbar  expand="lg" sticky="top">
     
      <Container>
      <Navbar.Brand as={Link} to="/" className="d-flex">
          <Image
            style={{ width: "105px" }}
            src="/images/nsmuhendislik-logo.jpg"
            alt={config.project.name}
          />
        </Navbar.Brand>
        <div
        className="name"
          style={{
            textAlign: "center",
            paddingTop: "11px",
            justifyContent: "center",
            alignItems: "center",
            
          }}
        >
          
          <div
            style={{
              cursor: "pointer",
              fontWeight: "700",
              fontSize: "25px",
              color: "#383086",
              marginTop:"10px"
              
            }}
          >
            NS Otomasyon
          </div>
          <p style={{ fontSize: "18px", fontWeight: "600"}}>
            Elektrik Makina Müh. San. ve Tic. Ltd. Şti.
          </p>
        </div>
        <Navbar.Toggle
          aria-controls="mainMenu"
          style={{ backgroundColor: "#383086" }}
          onClick={() => setShowMenu(!showMenu)}
        />

        <Navbar.Offcanvas
          id="mainMenu"
          aria-labelledby="offcanvas"
          placement="start"
          show={showMenu}
          onHide={handleMenuClose}
          style={{ color: "#383086" }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvas">
              <Image
                style={{ width: "95px", paddingLeft:"120px" }}
                src="/images/nsmuhendislik-logo.jpg"
                alt={config.project.name}
              />
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  marginTop: "10px",
                  color: "#383086",
                  marginLeft:"10px"
                }}
              >
               NS Otomasyon
              </div>
              <p style={{ fontSize: "16px", fontWeight: "600" }}>
               Elektrik Makina Müh. San. ve Tic. Ltd. Şti.
              </p>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            
            <MainMenu className="mainmenu justify-content-center flex-grow-1 pe-3" onClick={handleMenuClose} />

            <div
              className="btn btn-outline-secondary"
              style={{ marginLeft: "30px", paddingTop: "12px" }}
            >
              <Nav.Link as={Link} to="/iletisim" onClick={handleMenuClose}>
                BİZE ULAŞIN <FaWhatsapp style={{color:"green", justifyContent:"center", marginTop:"-4px" }} />
              </Nav.Link>
            </div>
            
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default Menubar;
 */

import React, { useState } from "react";
import { Container, Image, Navbar, Offcanvas } from "react-bootstrap";
import { config } from "../../helpers/config";
import { Link } from "react-router-dom";
import MainMenu from "./main-menu";
import "./menubar.scss";

const Menubar = () => {

  const [showMenu, setShowMenu] = useState(false);
  const handleMenuClose = () => setShowMenu(false);


  return (
    <Navbar expand="lg" sticky="top" className="bg-white">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <Image
            src="/images/nsmuhendislik-logo.jpg"
            width={120}
            alt={config.project.name}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="mainMenu" style={{ backgroundColor: "#de2022" }}
          onClick={() => setShowMenu(!showMenu)} />
        <Navbar.Offcanvas
          id="mainMenu"
          aria-labelledby="offcanvas"
          placement="start"
          show={showMenu}
          onHide={handleMenuClose}
          style={{ color: "white" }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvas">
              <Image
                src="/images/nsmuhendislik-logo.jpg"
                width={150}
                alt={config.project.name}
              />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <MainMenu className="text-center justify-content-center flex-grow-1 pe-1 " onLinkClick={handleMenuClose} />

            <div className="gap-2">
              <img
                src="/images/siemens-approved-partner.jpg"
                alt="Siemens Approved Partner"
               className="me-2"
              />
              <img
                src="/images/danfoss-authorised-dealers.jpg"
                alt="Danfoss Authorised Dealers"
           
              />
              <img
                src="/images/danfoss-services.jpg"
                alt="Danfoss Authorised Dealers"
         
              />
            </div>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default Menubar;
