import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./dealerships.scss"


const Dealerships = ({ image}) => {
  return (
    <Container className="dealerships">
    
        <Row>
          <Col md={12}>
          <div className="image" >
          <Card.Img src={`/images/dealerships/${image}`} />
          </div>
          </Col>
        </Row>
   
    </Container>
  );
};

export default Dealerships;
