import React from 'react';
import { Card, CardContent, CardMedia, Typography, Button, CardActions } from '@mui/material';


const ProductCard = ({ product }) => {
  return (
   <>
    <Card  className='text-center' sx={{ maxWidth: 250, margin: '1rem', marginTop:"30px"}}>
      <CardMedia
        component="img"
        height="auto"
        image={product.image}
        alt={product.name}
      />
      <CardContent >
        <Typography gutterBottom  component="div" height={50} style={{fontWeight:"bold"}}>
          {product.name}
        </Typography>
        <Typography variant="body2" color="text.primary" height={20}>
          {product.description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button  size="small" color="primary" href={product.catalogLink} >
          Katalogu Görüntüle
        </Button>
      </CardActions>
    </Card>
   </>
  );
}

export default ProductCard;
