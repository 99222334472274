import React from "react";
import About from "../components/about/about";
import BackgroundLogo from "../components/common/background-logo";
import { Helmet } from "react-helmet";

const AboutPage = () => {
  return (
    <>
      <Helmet>
        <title>Hakkımızda - Ns Mühendislik Otomasyon</title>
        <meta
          name="description"
          content="Ns Mühendislik Otomasyon, endüstriyel otomasyon çözümleri, mühendislik hizmetleri ve yenilikçi teknolojilerde uzmanlaşmış bir firmadır. Müşteri memnuniyeti odaklı, güvenilir ve kaliteli hizmet sunuyoruz."
        />

        <meta
          name="keywords"
          content="Ns Mühendislik, Otomasyon, Endüstriyel Otomasyon, Mühendislik Hizmetleri, Teknoloji, Yenilikçi Çözümler, Müşteri Memnuniyeti, Kaliteli Hizmet"
        />
      </Helmet>

      <div>
        <About />
        <BackgroundLogo />
      </div>
    </>
  );
};

export default AboutPage;
