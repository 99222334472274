import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import news from "../../helpers/data/news.json";
import News from "./news";
import PageHeader from "../common/page-header";


const NewsCard = () => {
  return (
    <Container>
        <PageHeader title="BLOG" />
      <Row className="g-5" xs={1} sm={2}>
        {news.map((item) => (
          <Col key={item.id}>
            <News {...item} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default NewsCard;
