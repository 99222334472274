import React, { useEffect } from 'react'
import Menubar from '../components/common/menubar'
import { Outlet, useLocation } from 'react-router-dom'
import Footer from '../components/common/footer'
import ScrollToTop from '../components/scroll/scroll-to-top'
import { scrollTop } from '../helpers/scroll'
import Topbar from '../components/common/topbar'

const UserLayout = () => {

  const { pathname } = useLocation();
  

  useEffect(() => {
    scrollTop();
  }, [pathname]);


  return (
   <>
    <Topbar/>
    <Menubar/>
    <Outlet/>
    <Footer/>
    <ScrollToTop/>
   </>
  )
}

export default UserLayout