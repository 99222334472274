import React from "react";
import { Carousel, Container, Image } from "react-bootstrap";
import slides from "../../helpers/data/slider.json";
import "./slider.scss";

const Slider = () => {
  return (
   
      <Carousel fade className="slider">
      {slides.map((slide) => (
        <Carousel.Item key={slide.id}>
          <Image
            src={`/images/slider/${slide.image}`}
            alt={slide.title}
            className="d-block w-100"
          />
         
        </Carousel.Item>
      ))}
    </Carousel>
  
  );
};

export default Slider;
