import React from 'react'
import PageHeader from './page-header'

const Gaski = () => {
  return (
    <div>
      <PageHeader title="YAPIM AŞAMASINDA !!!" />
    </div>
  )
}

export default Gaski