import React from 'react'
import { Container } from "react-bootstrap";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import "./our-dealerships.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import dealerships from "../../helpers/data/our-dealerships.json"
import Dealerships from './dealerships';

const OurDealerships = () => {
  return (
    <div className="our-dealerships">
      <Container>
        <h2>
          <div className="prev">
            <FaChevronCircleLeft />
          </div>
          <div>Markalarımız</div>
          <div className="next">
            <FaChevronCircleRight />
          </div>
        </h2>

        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          modules={[Navigation]}
          navigation={{
            nextEl: ".next",
            prevEl: ".prev",
          }}
          breakpoints={{
            576: {
              slidesPerView: 2,
            },
            992: {
              slidesPerView: 2,
            },
            1400: {
              slidesPerView: 2,
            },
          }}
        >
          {dealerships.map((item) => (
            <SwiperSlide key={item.id} src={`/images/dealerships/${item.image}`}  >
              <Dealerships {...item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </div>
  )
}

export default OurDealerships