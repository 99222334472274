
import "./contact-us.scss";
import { Container, Row } from "react-bootstrap";
import { FaArrowAltCircleRight} from "react-icons/fa";
import PageHeader from "../common/page-header";
import Spacer from "../common/spacer";
import BackgroundLogo from "../common/background-logo";
import Map from "./map";

const ContactUs = () => {
  return (
    <div>
      <h2 className="text-center">
        <PageHeader title="   İLETİŞİM " />
        <BackgroundLogo />
        <Map />
      </h2>
      <Container>
        <Row>
          <div
            className="mt-4 text-center d-flex gap-2 align-items-center justify-content-center"
            style={{ fontWeight: "bold", fontSize: "25px" }}
          >
           
              <div style={{ color: "#de2022" }}>
                NS Otomasyon Elektrik Makine
              </div>{" "}
              <div style={{ color: "darkblue" }}>
                Mühendislik San. ve Tic. Ltd. Şti.
              </div>
           
          </div>
          <div
            className="mt-4 text-center d-block"
            style={{ fontWeight: "600", fontSize: "18px"}}
          >
            <p>
              O.S.B. Mah. 2.Bölge 83207 Nolu Cad. No:2 Pk.26720
              Başpınar/Şehitkamil-Gaziantep
            </p>
            <div><a href="tel:+90-342-337-41-37"><FaArrowAltCircleRight /> Tel: +90 342 337 41 37</a></div>
            <div><a href="mailto:info@nsmuhendislik.com"><FaArrowAltCircleRight /> E-mail: info@nsmuhendislik.com</a></div>
          </div>

          <Spacer />

          
        </Row>
      </Container>
    </div>
  );
};

export default ContactUs;
