import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import "./product-card.scss"

const ProductCard = ({ name, image }) => {
  return (
    <Col md={4} className='mb-4 d-flex'>
      <Card>
        <Card.Img src={image} className="img-fluid" />
        <Card.Body>
          <Card.Title>{name}</Card.Title>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default ProductCard;
