import React from "react";
import BackgroundLogo from "../components/common/background-logo";
import Slider from "../components/home/slider";
import ScrollToTop from "../components/scroll/scroll-to-top";
import Spacer from "../components/common/spacer";
import OurDealerships from "../components/our-dealerships/our-dealerships";
import CustomerServices from "../components/customer-services/customer-services";
import FeaturedProducts from "../components/common/deneme-sayfasi";
import Services from "../components/common/services";
import { Helmet } from "react-helmet";
import VideoPlayer from "../components/player/video-player";

const HomePage = () => {

  
  return (
    <>
      <Helmet>
        <title>NS Mühendislik Elektrik-Elektronik-Otomasyon</title>
        <meta
          name="description"
          content="Siemens ve Danfoss bayisi olarak endüstriyel otomasyon ürünleri, PLC sistemleri, sürücüler, kontaktörler ve röleler satıyoruz. Mühendislik ve otomasyon çözümleriniz için güvenilir partneriniz."
        />
        <meta
          name="keywords"
          content="Siemens sürücü bayisi, Danfoss sürücü bayisi, Mühendislik ve otomasyon çözümleri, PLC otomasyon sistemleri, Endüstriyel şalt ürünleri, Endüstriyel kontaktörler, Otomasyon röleleri, Siemens PLC, Danfoss frekans konvertörü, Endüstriyel otomasyon şirketi, Otomasyon ürünleri satış"
        />
      </Helmet>
      <div>
        
        <Slider />
        <VideoPlayer/>
        <BackgroundLogo />
        <Spacer />
        <FeaturedProducts />
        {/* <Introduction /> */}
        <Spacer />
        <Services />
        <Spacer />
        <OurDealerships />
        <Spacer />
        <CustomerServices />
        <Spacer />
        <ScrollToTop />
      </div>
    </>
  );
};

export default HomePage;
