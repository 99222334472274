import React from 'react'
import PageHeader from '../../common/page-header'
import { Button } from 'react-bootstrap'
import "./danfoss-applications.scss"
import BackgroundLogo from '../../common/background-logo'

const DanfossApplications = () => {
  return (
    <div>
        <PageHeader title="DANFOSS UYGULAMALAR" />
        <BackgroundLogo/>
        <div className='img text-center' style={{ marginTop:"10px"}}>
        <img src="./images/danfoss-applications.jpg" alt="" />
        </div>
        <div className='text-center mt-2 fs-5'>
            <h3 className='fw-bold'>Sürücü Yapılandırıcı</h3>
            <p>Sürücü Yapılandırıcı, gereksinimlerinize tam olarak uyan Danfoss VLT® frekans konvertörünü yapılandırmak için gelişmiş, kullanımı kolay bir araçtır.</p>
            <p>Sürücü Yapılandırıcı, sipariş girişinde hataları önleyerek ihtiyacınız olan sürücü için benzersiz bir kod numarası oluşturur.</p>
            <p>Elinizdeki bir Tip Kodu'nu Sürücü Yapılandırıcı'ya girdiğinizde, bu kodun içindeki bilgileri açığa çıkararak size hangi özelliklere sahip bir sürücü olduğunu gösterir. Bu sayede mevcut sürücünüzün detaylarını kolayca öğrenebilirsiniz.</p>
            <p>Detaylar için <i>Teknik Dökümantasyonu</i> inceleyiniz.</p>
        </div>
        <div className='text-center mt-2'>
           <a href="https://vltconfig.danfoss.com/ipc_stdalo/init.do?scenario.xcm=z-drives-ipc_stdalo-public-p02"> <Button className="danfoss-button fs-6"> TEKNİK DÖKÜMANTASYON  </Button></a>
        </div>
    </div>
  )
}

export default DanfossApplications