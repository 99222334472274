import AppRouter from "./router";

function App() {
  return (
    <div >
     <AppRouter/>
    </div>
  );
}

export default App;
