export const config = {
  project: {
    name: "NS Otomasyon",
    slogan: "0 342 337 41 37  | info@nsmuhendislik.com",
    description: " ",
    version: "1.0.0",
  },
  contact: {
    phone1: "0342 337 41 37",

    email: "info@nsmuhendislik.com",
    address:
      "O.S.B mah. 2.Bölge 83207 Nolu Cad. No:2 Pk.26720 Başpınar/Şehitkamil-Gaziantep",
    mapURL: "https://maps.app.goo.gl/wRRposrYFHjqdJK16",
    mapEmbedURL:"https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d1336.9378782921106!2d37.305499219756044!3d37.157643277472786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d37.1577601!2d37.305481199999996!4m5!1s0x1531e29611ca150b%3A0x9e25dafe80c11087!2zQmHFn3DEsW5hciBPU0IsIE1lcml0IMSwcGxpaywgTm9sdSBDZCwgxZ5laGl0a2FtaWwvR2F6aWFudGVw!3m2!1d37.157444999999996!2d37.3052619!5e0!3m2!1str!2str!4v1716192807447!5m2!1str!2str",
    socialMedia: {
      
      facebook: "https://www.facebook.com/nsmuhendislikelektrikelektronikotomasyon/",
      linkedin: "https://www.linkedin.com/in/nsmuhendislik/",
      instagram: "https://www.instagram.com/ns_muhendislik/",
      youtube: "https://youtube.com",
    },
  },
  
  pageRoles: {
    dashboard: ["ADMİN"],
  },
};
