import React from 'react';
import { Container, Row, Col, Card, Button, Nav } from 'react-bootstrap';
import PageHeader from '../common/page-header';
import { Link } from 'react-router-dom';

const DanfossVideos = () => {
    const videoId1 = '6cuyKQDWNVk'; 
    const videoId2 = 'WXD_woaVAK4'; 
    const videoId3 = 'g_hDcL-J3dQ'; 
    const videoId4 = 'heSqpZ3IQHk'; 
    const videoId5 = 'DUhartxs3X8'; 
    const videoId6 = 'MvgpgSV30OY'; 
    const videoId7 = 'KW_0z6knoYo'; 
    
    return (
      <Container className="mt-5">
        <PageHeader title="DANFOSS EĞİTİM VİDEOLARI" />
        <Nav.Link as={Link} to="/video" className='text-center mt-5'> <Button   >Videolar Geri Dön</Button></Nav.Link>  
        <Row className="justify-content-md-center text-center">
          <Col md={12} className="d-flex gap-2 flex-wrap justify-content-center" style={{fontWeight:"700", fontSize:"17px"}}>
          <Card >
              <Card.Body>
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item custom-iframe"
                    src={`https://www.youtube.com/embed/${videoId1}`}
                    allowFullScreen
                    title="YouTube Video"
                  ></iframe>
                </div>
                <Card.Text className="mt-3" >
                VLT® HVAC Drive FC 102
                </Card.Text>
                <Button  variant="primary" href={`https://www.youtube.com/watch?v=${videoId1}`} target="_blank">
                  YouTube'da izle
                </Button>
              </Card.Body>
            </Card>
            <Card >
              <Card.Body>
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item custom-iframe"
                    src={`https://www.youtube.com/embed/${videoId2}`}
                    allowFullScreen
                    title="YouTube Video"
                  ></iframe>
                </div>
                <Card.Text className="mt-3" >
                FC102 ve FC202 ile Kaskat uygulaması
                </Card.Text>
                <Button variant="primary" href={`https://www.youtube.com/watch?v=${videoId2}`} target="_blank">
                  YouTube'da izle
                </Button>
              </Card.Body>
            </Card>
            <Card >
              <Card.Body>
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item custom-iframe"
                    src={`https://www.youtube.com/embed/${videoId3}`}
                    allowFullScreen
                    title="YouTube Video"
                  ></iframe>
                </div>
                <Card.Text className="mt-3" >
                VLT® HVAC Drive FC 102
                </Card.Text>
                <Button variant="primary" href={`https://www.youtube.com/watch?v=${videoId3}`} target="_blank">
                  YouTube'da izle
                </Button>
              </Card.Body>
            </Card>
            <Card >
              <Card.Body>
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item custom-iframe"
                    src={`https://www.youtube.com/embed/${videoId4}`}
                    allowFullScreen
                    title="YouTube Video"
                  ></iframe>
                </div>
                <Card.Text className="mt-3" >
                Danfoss VLT Automation FC302
                </Card.Text>
                <Button variant="primary" href={`https://www.youtube.com/watch?v=${videoId4}`} target="_blank">
                  YouTube'da izle
                </Button>
              </Card.Body>
            </Card>
          <Card>
              <Card.Body>
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item custom-iframe"
                    src={`https://www.youtube.com/embed/${videoId5}`}
                    allowFullScreen
                    title="YouTube Video"
                  ></iframe>
                </div>
                <Card.Text className="mt-3">
                Danfoss Drives Türkiye iC2 Mikro
                </Card.Text>
                <Button variant="primary" href={`https://www.youtube.com/watch?v=${videoId5}`} target="_blank">
                  YouTube'da izle
                </Button>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item custom-iframe"
                    src={`https://www.youtube.com/embed/${videoId6}`}
                    allowFullScreen
                    title="YouTube Video"
                  ></iframe>
                </div>
                <Card.Text className="mt-3">
                Yangın Modu Nedir?
                </Card.Text>
                <Button variant="primary" href={`https://www.youtube.com/watch?v=${videoId6}`} target="_blank">
                  YouTube'da izle
                </Button>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item custom-iframe"
                    src={`https://www.youtube.com/embed/${videoId7}`}
                    allowFullScreen
                    title="YouTube Video"
                  ></iframe>
                </div>
                <Card.Text className="mt-3">
                MCB102 Encoder kurulumu
                </Card.Text>
                <Button variant="primary" href={`https://www.youtube.com/watch?v=${videoId7}`} target="_blank">
                  YouTube'da izle
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
}

export default DanfossVideos