import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { config } from "../../helpers/config";
import "./footer.scss";
import SocialMenu from "./social-menu";
import ContactMenu from "./contact-menu";
import FooterMenu from "./footer-menu";

const Footer = () => {
  return (
    <footer
      style={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container>
        <Row className="g-5">
          <Col lg={3}>
            <Link to="/">
              <Image
                style={{ width: "120px" }}
                src="/images/nsmuhendislik-logo.jpg"
                alt={config.project.name}
              />
            </Link>
            <p style={{ paddingTop: "10px" }}>{config.project.description}</p>
          </Col>
          <Col xs={6} sm={6} md={4} lg={3}>
            <h3 style={{ paddingLeft: "30px"}}>KATEGORİ</h3>
            <FooterMenu className="main flex-column " style={{fontWeight:"bold" }} />
          </Col>
          <Col xs={6} sm={6} md={4} lg={3}>
            <h3 style={{ paddingLeft: "10px" }}>SOSYAL MEDYA</h3>
            <SocialMenu className="flex-column" style={{fontWeight:"bold" }} />
          </Col>
          <Col md={4} lg={3}>
            <h3 style={{ paddingLeft: "10px" }}>İLETİŞİM</h3>
            <ContactMenu className="flex-column" style={{fontWeight:"bold" }} />
          </Col>
        </Row>
        <p
          className="text-center"
          style={{ marginTop: "90px", fontWeight: "bold", fontSize: "17px" }}
        >
          {" "}
          © 2024{" "}
          <a href="https://api.whatsapp.com/send?phone=" >NS Mühendislik</a> |
          Tüm Hakları Saklıdır.
        </p>
        <p className="text-center mt-3" style={{fontSize:"13px"}}><a style={{textDecoration:"none"}} href="https://www.linkedin.com/in/yasin-ibin-9b4025277/">Designed by Yasin İBİN</a></p>
      </Container>
    </footer>
  );
};

export default Footer;
