import React, { useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { FiClock } from "react-icons/fi";
import "./news.scss";

const News = ({ image, title, day, desc }) => {
  const [modalShow, setModalShow] = useState(false);

  const handleShow = () => setModalShow(true);
  const handleClose = () => setModalShow(false);

  return (
    <>
      <Card className="news-card">
        <div className="row g-0">
          <div className="col-md-3 image">
            <Card.Img src={`/images/news/${image}`} className="img-fluid"  style={{ height: "100%", objectFit: "cover" }}/>
            
          </div>
          <div className="col-md-8">
            <Card.Body className="d-flex flex-column justify-content-between">
              <div>
                <Card.Title className="fw-bold">{title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  <FiClock /> {day}
                </Card.Subtitle>
                <Card.Text className="text-truncate">{desc}</Card.Text>
              </div>
              <div className="text-end text-center mt-3">
                <Button onClick={handleShow} variant="secondary">
                  Devamını Oku
                </Button>
              </div>
            </Card.Body>
          </div>
        </div>
      </Card>

      <Modal show={modalShow} onHide={handleClose} size="md">
        <Modal.Body className="d-flex flex-column align-items-center">
          <div className="image col-md-7 d-flex justify-content-center ">
            <Card.Img style={{ height: "20%" }} src={`/images/news/${image}`} />
          </div>
          <div style={{ maxHeight: "50vh", overflowY: "auto", marginTop: "1rem", textAlign: "center" }}>
            <p>{day}</p>
            <p>{desc}</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default News;
