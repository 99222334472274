import { RouterProvider, createBrowserRouter } from "react-router-dom";
import UserLayout from "../layouts/user-layout";
import HomePage from "../pages/home-page";
import AboutPage from "../pages/about-page";
import OurProducts from "../components/our-products/our-products";
import Siemens from "../components/our-products/siemens";
import Danfoss from "../components/our-products/danfoss";
import ContactUs from "../components/contact/contact-us";
import Projects from "../components/projects/projects";
import ProductsList from "../components/catalog/products";
import PlcSubCategory from "../components/our-products/plc-sub-category";
import SubCategory from "../components/our-products/sub-category";
import ProductCard from "../components/our-products/product-card";
import SiemensSubCategories from "../components/our-products/siemens-sub-categories";
import Gaski from "../components/common/gaski";
import Tpao from "../components/common/tpao";
import Suski from "../components/common/suski";
import Documents from "../components/documents/documents";
import News from "../components/news/news";
import Videos from "../components/documents/videos";
import UserGuide from "../components/documents/user-guide";
import ServoSubCategory from "../components/our-products/ser-sub-category";
import HmiSubCategory from "../components/our-products/hmi-sub-category";
import DanfossVideos from "../components/documents/danfoss-videos";
import SiemensVideos from "../components/documents/siemens-videos";
import DanfossUserGuide from "../components/documents/user-guide/danfoss-user-guide";
import SiemensUserGuide from "../components/documents/user-guide/siemens-user-guide";
import NewsCard from "../components/news/news-card";
import DanfossApplications from "../components/documents/applications/danfoss-applications";
import SiemensApplications from "../components/documents/applications/siemens-applications";

const router = createBrowserRouter([
  {
    path: "/",
    element: <UserLayout />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "hakkimizda",
        element: <AboutPage/>,
      },
      {
        path: "urunlerimiz",
        element: <OurProducts/>,
      },
      {
        path: "siemens/plc",
        element: <PlcSubCategory/>,
      },
      {
        path: "siemens/servo",
        element: <ServoSubCategory/>,
      },
      {
        path: "siemens/hmi",
        element: <HmiSubCategory/>,
      },
      {
        path:"danfoss",
        element: <Danfoss/>
      },
      {
        path: "iletisim",
        element: <ContactUs/>,
      },
      {
        path: "haberler",
        element: <NewsCard/>,
      },
      {
        path:"projeler",
        element: <Projects/>,
      },
      {
        path:"katalog",
        element: <ProductsList/>,
      },
      {
        path: "gaski",
        element: <Gaski />,
      },
      {
        path: "tpao",
        element: <Tpao />,
      },
      {
        path: "suski",
        element: <Suski />,
      },
      {
        path:"video",
        element: <Videos/>
      },
      {
        path:"danfoss-video",
        element: <DanfossVideos/>
      },
      {
        path:"siemens-video",
        element: <SiemensVideos/>
      },
      {
        path:"dokumanlar",
        element: <Documents/>
      },
      {
        path:"danfoss-kilavuz",
        element: <DanfossUserGuide/>
      },
      {
        path:"siemens-kilavuz",
        element: <SiemensUserGuide/>
      },
      
      {
        path:"kılavuz",
        element: <UserGuide/>
      },
      {
        path:"danfoss-uygulamalar",
        element: <DanfossApplications/>
      },
      {
        path:"siemens-uygulamalar",
        element: <SiemensApplications/>
      },
      
      
      {
        path:"",
        element: <ProductCard/>,
      },
    
      
    ],
  },
]);

const AppRouter = () => {
  return <RouterProvider router={router} />;
};

export default AppRouter;
