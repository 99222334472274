import PageHeader from "../common/page-header";
import "./projects.scss";
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

const Projects = () => {
   
  const videoId = 'g_hDcL-J3dQ'; 
  const videoId1 = 'heSqpZ3IQHk'; 
  const videoId2 = 'pRRHgc28zq4'; 
  const videoId3 = '31KQI8Vjvck'; 
  
  return (
    <Container className="mt-5">
      <PageHeader title="EĞİTİM VİDEOLARI" />
      <Row className="justify-content-md-center">
        <Col md={12} className="d-flex gap-2 flex-wrap justify-content-center" style={{fontWeight:"700", fontSize:"17px"}}>
        <Card >
            <Card.Body>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item custom-iframe"
                  src={`https://www.youtube.com/embed/${videoId3}`}
                  allowFullScreen
                  title="YouTube Video"
                ></iframe>
              </div>
              <Card.Text className="mt-3" >
              SIMATIC S7-1200 - the Basic Controller
              </Card.Text>
              <Button variant="primary" href={`https://www.youtube.com/watch?v=${videoId3}`} target="_blank">
                YouTube'da izle
              </Button>
            </Card.Body>
          </Card>
        <Card>
            <Card.Body>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item custom-iframe"
                  src={`https://www.youtube.com/embed/${videoId2}`}
                  allowFullScreen
                  title="YouTube Video"
                ></iframe>
              </div>
              <Card.Text className="mt-3">
              SINAMICS V90 with PROFINET
              </Card.Text>
              <Button variant="primary" href={`https://www.youtube.com/watch?v=${videoId2}`} target="_blank">
                YouTube'da izle
              </Button>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item custom-iframe"
                  src={`https://www.youtube.com/embed/${videoId}`}
                  allowFullScreen
                  title="YouTube Video"
                ></iframe>
              </div>
              <Card.Text className="mt-3">
               Danfoss VLT Midi Drive FC 280
              </Card.Text>
              <Button variant="primary" href={`https://www.youtube.com/watch?v=${videoId}`} target="_blank">
                YouTube'da izle
              </Button>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item custom-iframe"
                  src={`https://www.youtube.com/embed/${videoId1}`}
                  allowFullScreen
                  title="YouTube Video"
                ></iframe>
              </div>
              <Card.Text className="mt-3">
              Danfoss VLT Automation FC302
              </Card.Text>
              <Button variant="primary" href={`https://www.youtube.com/watch?v=${videoId1}`} target="_blank">
                YouTube'da izle
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Projects;
