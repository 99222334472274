import React from 'react'
import PageHeader from './page-header'

const Tpao = () => {
  return (
    <div>
      <PageHeader title="YAPIM AŞAMASINDA !!!" />

    </div>
  )
}

export default Tpao