import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const CustomerServices = () => {
  return (
    <Container>
      <Row className="align-items-center">
        <Col md={6} className="order-md-2">
          <img className="w-100" src="images/teknik-destek.jpg" alt="" />
        </Col>
        <Col md={6} className="order-md-1">
          <div className="pt-3 text-center">
            <h3>Müşteri Hizmetleri Merkezimize Ulaşın</h3>
            <p>
              Projelerinizde ihtiyaç duyduğunuz tüm teknik desteği sağlıyoruz.
              NS Mühendislik referanslarından aldığı güçler tüm projelerinde
              müşteri memnuniyetini en önemli öncelik olarak kabul etmektedir.
            </p>
            <ul className="list-unstyled">
              <li>Fiyat ve stok bilgisi edinin</li>
              <li>Siparişinizle ilgili soru sorun</li>
              <li>Yedek parça ve ürün değişimi talep edin</li>
              <li>Teknik destek alın</li>
            </ul>
            <div className="text-center">
              <Button variant="primary">
                <a
                  href="iletisim"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Bize Ulaşın
                </a>
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CustomerServices;
