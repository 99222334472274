import React from 'react'
import PageHeader from '../../common/page-header'
import { Button } from 'react-bootstrap'
import "./siemens-applications.scss";

const SiemensApplications = () => {
  return (
    <div>
        <PageHeader title="SIEMENS UYGULAMALARI" />
        <div className='img text-center' style={{ marginTop:"10px"}}>
        <img src="./images/siemens-applications-2.jpg" alt="" />
        </div>
        <div className='text-center mt-3 fs-5'>
        <h3 className='fw-bold'>TİA PORTAL TOOLS</h3>
        <p>Siemens'in Tia Selection Tool yazılımı mekanik sistem ihtiyaçlarını karşılayabilecek en verimli elektrik motorunu ve sürücüyü seçebilmemize olanak tanır.</p>
        <p>İstenilen işi yapmak için yazılıma girilen bilgiler doğrultusunda mekanik sisteme en uygun motor, sürücü, kablo, redüktör seçebilmenize yardımcı olur.</p>
        <p><p>Detaylar için <i>Teknik Dökümantasyonu</i> inceleyiniz.</p></p>
        </div>
        <div className='text-center mt-2'>
           <a href="https://mall.industry.siemens.com/tstcloud/#/Start"> <Button className="siemens-button fs-6"> TEKNİK DÖKÜMANTASYON  </Button></a>
        </div>

    </div>
  )
}

export default SiemensApplications