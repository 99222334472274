import React from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { config } from "../../helpers/config";
import "./topbar.scss";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";

const Topbar = () => {
  return (
    <div className="topbar">
      <Container>
        <Row className="d-flex justify-content-end align-items-center">
          <Col md={3} className="slogan d-none d-md-block" style={{color:"white"}}>
            {config.contact.phone1} {config.contact.email}
          </Col>
          <Col className="d-flex justify-content-end align-items-center" style={{color:"white"}}>
          <Nav.Link href={config.contact.socialMedia.linkedin} target="_blank" >
          <FaLinkedin className="icon"/> 
          </Nav.Link>
          <Nav.Link href={config.contact.socialMedia.instagram} target="_blank" >
          <FaInstagram className="icon"/>
          </Nav.Link>
          <Nav.Link href={config.contact.socialMedia.facebook} target="_blank" >
          <FaFacebook className="icon"/>
          </Nav.Link>  
           
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Topbar;
