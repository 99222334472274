import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./about.scss";
import Services from "../common/services";

const About = () => {
  return (
    <div>
      <Container>
        <div className="text-center mt-5 mb-5">
          <img
            src="images/nsmuhendislik-logo.jpg"
            alt=""
            style={{ width: "150px" }}
          />

          <div className="mt-4">
            NS Mühendislik, 2011 yılında Gaziantep’te kurulmuş olup, KOSGEB
            bünyesinde yürüttüğü ar-ge ve inovasyon projeleri ile Türkiye’nin
            önde gelen 100 firması arasında yer almayı başarmıştır. Şirketimiz,
            kurucumuz Nuh Eroğlu’nun önderliğinde, otomasyon sistemleri,
            invertör satışı ve tamiratı, elektronik kart tamiratı ve SCADA
            sistemleri gibi projeleri başarıyla gerçekleştirmektedir. Yerinde,
            hızlı ve etkili çözümler üretmeyi ilke edinen NS Mühendislik, tüm
            projelerini başarıyla tamamlamıştır. Müşterilerimizin ihtiyaç
            duyduğu uygulamaları, mevcut sistemleri üzerinde sorunsuz çalışacak
            şekilde geliştirmekteyiz. Uygun yöntem ve araçları belirleyerek,
            sektörel deneyimimizle birleştirip beklentilerinizi maksimum düzeyde
            karşılamaktayız. Kurulduğumuz günden bu yana teknolojik gelişmeleri
            ve sektör ihtiyaçlarını yakından takip ederek, müşteri odaklı
            politikamızı “Kalite, Karşılıklı Güven ve Süreklilik” prensipleri
            üzerine inşa ettik. Rekabet ortamında kaliteden ve müşteri
            memnuniyetinden ödün vermeyen NS Mühendislik, bu prensibiyle
            müşterilerinin güvenini kazanmıştır. Firmamıza duyduğunuz güvenin
            karşılığını, beklentileriniz doğrultusunda sizlere sunmak en önemli
            misyonumuzdur. Bugüne kadar elde ettiğimiz başarının artarak devam
            edeceğine olan inancımız, sizlerin tercihleriyle güç kazanmaktadır.
            NS Mühendislik olarak, en iyi çözümü en doğru adreste bulduğunuz,
            dürüst ve haklı rekabet koşullarına sadık çözüm ortağınız olmanın
            gururunu sizlerle paylaşmaya devam edeceğiz.
          </div>
        </div>

        <Services />
      </Container>
    </div>
  );
};

export default About;
