import React from 'react'
import PageHeader from '../../common/page-header';
import "./user-guide.scss"

const DanfossUserGuide = () => {
  const guides = [
    { 
      name: 'VLT® Mikro Sürücü FC 51', 
      link: 'https://files.danfoss.com/download/Drives/doc_MG02A242.pdf',
      description: 'VLT® HVAC Sürücü FC 102'
    },
    { 
      name: 'VLT® HVAC Sürücü FC 102', 
      link: 'https://files.danfoss.com/download/Drives/MG11AK42.pdf',
      description: 'VLT® HVAC Sürücü FC 102'
    },
    { 
      name: 'VLT® AQUA Drive FC 202', 
      link: 'https://files.danfoss.com/download/Drives/MG22A142.pdf',
      description: 'VLT® AQUA Drive FC 202'
    },
    { 
      name: 'VLT® Midi Drive FC 280', 
      link: 'https://files.danfoss.com/download/Drives/MG07A142.pdf',
      description: 'VLT® Midi Drive FC 280'
    },
    { 
      name: 'VLT® AutomationDrive FC 302', 
      link: 'https://files.danfoss.com/download/Drives/MG38A142.pdf',
      description: 'VLT® AutomationDrive FC 302'
    },
    { 
      name: 'Vacon® 10 DC Drives', 
      link: 'https://files.danfoss.com/download/Drives/Vacon-10-Quick-Guide-DPD01025D1-TR.pdf',
      description: 'Vacon® 10 DC Drives'
    },
    { 
      name: 'Vacon® 20 DC Drives', 
      link: 'https://files.danfoss.com/download/Drives/Vacon-20-Quick-Guide-DPD00855A-TR.PDF',
      description: 'Vacon® 20 DC Drives'
    },
    { 
      name: 'Vacon® 100 Flow Frekans Dönüştürücü', 
      link: 'https://files.danfoss.com/download/Drives/Vacon-100-FLOW-Application-Manual-DPD01264F-TR.pdf',
      description: 'Vacon® 100 Flow Frekans Dönüştürücü'
    },
  ];

  return (
    <div className="guide-container">
      <PageHeader title="Danfoss Kullanım Kılavuzları" />
      <ul className="guide-list text-center mt-3">
        {guides.map((guide, index) => (
          <li key={index} className="guide-item fw-bold">
            <a href={guide.link} target="_blank" rel="noopener noreferrer" className="guide-link">
              {guide.name}
            </a>
            <p className="guide-description">{guide.description}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default DanfossUserGuide